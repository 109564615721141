import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Axios from 'axios';
import {Store} from '../Store';
import Alert from 'react-bootstrap/Alert';
import {toast} from 'react-toastify';
import {getError} from '../utils';
import {api} from '../Api';

export default function WithdrawScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [AvilablePoint, setAvilablePoint] = useState(0);
  const [convertFrom, setConvertFrom] = useState(0);
  const [convertTo, setConvertTo] = useState('Agent');
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(0);
  const [withdrawCharge, setWithdrawCharge] = useState(0);
  const grandTotal = Math.floor(
    parseInt(amount) - (amount * withdrawCharge) / 100,
    2
  );

  const [profile, setProfile] = useState();
  const [mobilebank, setmobilebank] = useState();
  const [mobilebankN, setmobilebankN] = useState();
  const [bank, setBank] = useState();
  const [bankN, setBankN] = useState();

  async function getUserSettings() {
    const { data } = await Axios.post(
      api + '/api/availablebalance/user',
      { id: userInfo._id },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    // window.alert(data.mobilebankaccount);
    setmobilebank(data.mobilebankname);

    if (
      data.mobilebankname === null ||
      data.mobilebankaccount === null ||
      data.fatherphone === null ||
      data.motherphone === null ||
      data.nid === null ||
      data.spousephone === null ||
      data.benificiaryname === null ||
      data.benificiarynid === null ||
      data.benificiaryrelation === null ||
      data.profilePhoto ===
        'https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png' ||
      data.sign === 'null'
    ) {

      setProfile(0);
    } else {
      setmobilebank(data.mobilebankname);
      setmobilebankN(data.mobilebankaccount);
      setBank(data.bankname);
      setBankN(data.bankaccount);
      setProfile(1);
    }
  }
const [incomeG, setIncomeG] = useState(0);
  const fetchData = async () => {
    try {
      const { data } = await Axios.get(
        api + `/api/users/point/${userInfo._id}`,
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      setWithdrawCharge(data.withdrawCharge);
      setAvilablePoint(data.availablebalance);
      setIncomeG(data.incomeGeneration);
      setmatchingPoint(
        data.totalMatchingAvl.length > 1
          ? data.totalMatchingAvl
              .map((item) => item.amount)
              .reduce((prev, next) => prev + next)
          : data.totalMatchingAvl[0].amount
      );
    } catch (error) {
      // window.alert(error);
    }
    global();
  };

  const globalsales = [0];
  const [myglobalseles, setMyglobalsales] = useState();
  async function global() {
    try {
      const { data } = await Axios.post(
        api + '/api/costs/myglobal',
        { username: userInfo.username },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );

      for (var i = 0; i < data.length; i++) {
        if (data[i].status === 'Pending') {
          globalsales.push(data[i].amount);
        }
      }
      setMyglobalsales(globalsales.reduce((a, b) => a + b, 0));
      console.log(data);
    } catch (error) {}
  }

  useEffect(() => {
    getUserSettings();
    fetchData();
  }, [userInfo]);

  const convert = async () => {
    if (
      ConvertSource !== '0' &&
      convertFrom !== 0 &&
      convertTo !== '0' &&
      amount > 0 &&
      password.length > 4
    ) {
      if (ConvertSource === 'matching' && grandTotal > MatchingPoint) {
        toast.error('Invalid Amount. Please check again. ');
      } else if (
        ConvertSource !== 'matching' &&
        ConvertSource !== 'global' &&
        grandTotal > AvilablePoint
      ) {
        toast.error('Invalid Amount. Please check again. ');
      } else if (ConvertSource === 'global' && grandTotal > myglobalseles) {
        toast.error('Invalid Amount. Please check again. ');
      } else {
        try {
          setloading(1);

          const { data } = await Axios.post(
            api + `/api/users/withdraw/`,
            {
              withdrawSource: ConvertSource,
              paymentmethod: convertFrom,
              username: userInfo.username,
              to: convertTo,
              amount: grandTotal,
                charge: amount - grandTotal,
                password: password,
            },
              {
                  headers: {authorization: `Bearer ${userInfo.token}`},
              }
          );
            toast.info(data.message);
            fetchData();
            setAmount(0);
            setPassword('');
            setloading(0);

        } catch (error) {
            fetchData();
            setAmount(0);
            setPassword('');
            toast.error(getError(error));
            setloading(0);

        }
      }
    } else {
      toast.error('Invalid Information. Please check again.');
    }
  };

  function gotoHome() {
    navigate('/user-panel');
  }
  const [MatchingPoint, setmatchingPoint] = useState(0);
  const [ConvertSource, setConvertSource] = useState(0);
  const [readOnly, setReadOnly] = useState(false);

  function setConvertFromF(value) {
    setConvertSource(value);
    if (value === 'matching') {
      setAmount(MatchingPoint);
      setReadOnly(true);
    }
    if (value === 'global') {
      setAmount(myglobalseles);
      setReadOnly(true);
    }
    if (value === "incomegeneration") {
			setAmount(incomeG);
			setReadOnly(true);
		}
  }
  return (
		<div>
			<Helmet>
				<title>Withdraw </title>
			</Helmet>
			<Container className="no-gutter" style={{}}>
				<div className="card">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i className="fa fa-long-arrow-left" aria-hidden="true"></i> {"  "}{" "}
						Withdraw
					</div>

					<div className="card-body w-90" style={{ margin: "auto" }}>
						{profile ? (
							<Alert key="info" variant="info">
								Your Profile is Updated and ready for withdraw. Please check
								<Alert.Link href="/settings#/settings"> Settings</Alert.Link> &
								check Your Bank Information.
							</Alert>
						) : (
							<Alert key="danger" variant="danger">
								Please Complete Your Profile Before Withdraw. Go To
								<Alert.Link href="/settings#/settings"> Settings </Alert.Link> &
								Update Your Profile.
							</Alert>
						)}
						<span>
							Available Point: {AvilablePoint} | Group Sales: {MatchingPoint} |
							Global Sales : {myglobalseles} | Available Income Generation:{" "}
							{incomeG} | Income Generation Charge : {withdrawCharge}%
						</span>
						<div className="input-group mb-3">
							<div className="input-group-text">
								<i className="fa fa-level-down" aria-hidden="true"></i>
							</div>
							<select
								className="form-control "
								required
								onChange={(e) => setConvertFromF(e.target.value)}
							>
								<option value="0">Withdraw From</option>
								<option value="availablebalance">Available Balance</option>
								<option value="matching">Group Sales</option>
								<option value="global">Global Sales</option>
								<option value="incomegeneration">Income Generation</option>
							</select>{" "}
						</div>
						<div className="input-group mb-3">
							<div className="input-group-text">
								<i className="fa fa-level-down" aria-hidden="true"></i>
							</div>
							<select
								className="form-control "
								required
								onChange={(e) => setConvertFrom(e.target.value)}
							>
								<option value="0">Select Payment Method</option>
								<option value="bkash">
									{mobilebank
										? mobilebank + " - " + mobilebankN
										: "No Mobile Banking Information"}
								</option>
								<option value="bank">
									{bank ? bank + " - " + bankN : "No Bank Information"}
								</option>
							</select>{" "}
						</div>
						<div className="input-group mb-3">
							<div className="input-group-text">
								<i className="fa fa-user" aria-hidden="true"></i>
							</div>
							<input
								type="text"
								className="form-control"
								required
								value={convertTo}
								readOnly
							/>
						</div>
						<div className="mb-3">
							<div className="input-group">
                                <div className="input-group-text">
                                    <i className="fa fa-dollar" aria-hidden="true"></i>
                                </div>
                                <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Amount"
                                    readOnly={readOnly}
                                    required
                                    disabled={loading || !profile ? true : false}

                                    value={ConvertSource === "matching" ? MatchingPoint : amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                ></input>
							</div>
							{amount > 0 ? (
								<span className="small">
									Net Payable:
									{grandTotal}
								</span>
							) : (
								<span className="small"></span>
							)}{" "}
						</div>
						<div className="input-group mb-3">
							<div className="input-group-text">
								<i className="fa fa-asterisk" aria-hidden="true"></i>
							</div>
							<input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                required
                                disabled={loading || !profile ? true : false}
                                onChange={(e) => setPassword(e.target.value)}
							/>
						</div>

						<Button
							variant="outline-success w-100"
							className={loading || !profile ? "disabled" : ""}
							onClick={convert}
						>
							{loading ? (
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
							) : (
								""
							)}{" "}
							Submit
						</Button>
					</div>

					<div style={{ height: "51vh" }}> {""}</div>
				</div>
			</Container>
		</div>
	);
}
